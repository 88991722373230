
const icons = {
  'megasreda-logo': 'CoreIconIconsMegasredaLogo',
  'megasreda-no-fill': 'CoreIconIconsMegasredaNoFill',
  'megasreda-white': 'CoreIconIconsMegasredaWhite',
  verification: 'CoreIconIconsVerification',
  add: 'CoreIconIconsAdd',
  burger: 'CoreIconIconsBurger',
  'sign-in': 'CoreIconIconsSignIn',
  'sign-out': 'CoreIconIconsSignOut',
  home: 'CoreIconIconsHome',
  apps: 'CoreIconIconsApps',
  flame: 'CoreIconIconsFlame',
  'flame-gradient': 'CoreIconIconsFlameGradient',
  navigation: 'CoreIconIconsNavigation',
  play: 'CoreIconIconsPlay',
  'play-alt': 'CoreIconIconsPlayAlt',
  briefcase: 'CoreIconIconsBriefcase',
  'paper-plane': 'CoreIconIconsPaperPlane',
  bookmark: 'CoreIconIconsBookmark',
  following: 'CoreIconIconsFollowing',
  list: 'CoreIconIconsList',
  'list-check': 'CoreIconIconsListCheck',
  smile: 'CoreIconIconsSmile',
  close: 'CoreIconIconsClose',
  check: 'CoreIconIconsCheck',
  checked: 'CoreIconIconsChecked',
  ban: 'CoreIconIconsBan',
  mountains: 'CoreIconIconsMountains',
  dots: 'CoreIconIconsDots',
  eye: 'CoreIconIconsEye',
  edit: 'CoreIconIconsEdit',
  'edit-full': 'CoreIconIconsEditFull',
  save: 'CoreIconIconsSave',
  preload: 'CoreIconIconsPreload',
  'eye-crossed': 'CoreIconIconsEyeCrossed',
  'arrow-down': 'CoreIconIconsArrowDown',
  'user-add': 'CoreIconIconsUserAdd',
  'user-following': 'CoreIconIconsUserFollowing',
  'check-box': 'CoreIconIconsCheckBox',
  layers: 'CoreIconIconsLayers',
  computer: 'CoreIconIconsComputer',
  picture: 'CoreIconIconsPicture',
  share: 'CoreIconIconsShare',
  whatsapp: 'CoreIconIconsWhatsapp',
  'whatsapp-light': 'CoreIconIconsWhatsappLight',
  telegram: 'CoreIconIconsTelegram',
  'telegram-no-fill': 'CoreIconIconsTelegramNoFill',
  'telegram-btn': 'CoreIconIconsTelegramBtn',
  'telegram-light': 'CoreIconIconsTelegramLight',
  vk: 'CoreIconIconsVk',
  'vk-no-fill': 'CoreIconIconsVkNoFill',
  'vk-r': 'CoreIconIconsVkRounded',
  ok: 'CoreIconIconsOk',
  'ok-no-fill': 'CoreIconIconsOkNoFill',
  facebook: 'CoreIconIconsFacebook',
  twitter: 'CoreIconIconsTwitter',
  youtube: 'CoreIconIconsYoutube',
  instagram: 'CoreIconIconsInstagram',
  teletype: 'CoreIconIconsTeletype',
  'teletype-no-fill': 'CoreIconIconsTeletypeNoFill',
  'yandex-zen': 'CoreIconIconsYandexZen',
  'yandex-r': 'CoreIconIconsYandexRounded',
  'yandex-zen-no-fill': 'CoreIconIconsYandexZenNoFill',
  'share-link': 'CoreIconIconsShareLink',
  email: 'CoreIconIconsEmail',
  settings: 'CoreIconIconsSettings',
  quote: 'CoreIconIconsQuote',
  camera: 'CoreIconIconsCamera',
  octogram: 'CoreIconIconsOctogram',
  laugh: 'CoreIconIconsLaugh',
  expand: 'CoreIconIconsExpand',
  incognito: 'CoreIconIconsIncognito',
  rouble: 'CoreIconIconsRouble',
  info: 'CoreIconIconsInfo',
  flag: 'CoreIconIconsFlag',
  megaphone: 'CoreIconIconsMegaphone',
  shop: 'CoreIconIconsShop',
  rocket: 'CoreIconIconsRocket',
  interrogation: 'CoreIconIconsInterrogation',
  building: 'CoreIconIconsBuilding',
  redo: 'CoreIconIconsRedo',
  'business-account': 'CoreIconIconsBusinessAccount',
  'moderator-account': 'CoreIconIconsModeratorAccount',
  text: 'CoreIconIconsText',
  search: 'CoreIconIconsSearch',
  'text-check': 'CoreIconIconsTextCheck',
  cross: 'CoreIconIconsCross',
  'cross-small': 'CoreIconIconsCrossSmall',
  link: 'CoreIconIconsLink',
  users: 'CoreIconIconsUsers',
  sheets: 'CoreIconIconsSheets',
  calendar: 'CoreIconIconsCalendar',
  infinity: 'CoreIconIconsInfinity',
  broom: 'CoreIconIconsBroom',
  'settings-sliders': 'CoreIconIconsSettingsSliders',
  pencil: 'CoreIconIconsPencil',
  lock: 'CoreIconIconsLock',
  unlock: 'CoreIconIconsUnLock',
  download: 'CoreIconIconsDownload',
  pdf: 'CoreIconIconsPdf',
  'arrow-up-small': 'CoreIconIconsArrowUpSmall',
  trash: 'CoreIconIconsTrash',
  'align-justify': 'CoreIconIconsAlignJustify',
  arrows: 'CoreIconIconsArrows',
  thumbtack: 'CoreIconIconsThumbtack',
  laptop: 'CoreIconIconsLaptop',
  'angle-left': 'CoreIconIconsAngleLeft',
  'angle-right': 'CoreIconIconsAngleRight',
  'letter-case': 'CoreIconIconsLetterCase',
  learning: 'CoreIconIconsLearning',
  bulb: 'CoreIconIconsBulb',
  'magic-wand': 'CoreIconIconsMagicWand',
  cap: 'CoreIconIconsCap',
  exclamation: 'CoreIconIconsExclamation',
  'navigator-streams': 'CoreIconIconsNavigatorStreams',
  'navigator-broadcasts': 'CoreIconIconsNavigatorBroadcasts',
  'navigator-events': 'CoreIconIconsNavigatorEvents',
  'navigator-authors': 'CoreIconIconsNavigatorAuthors',
  'navigator-companys': 'CoreIconIconsNavigatorCompanys',
  'navigator-reviews': 'CoreIconIconsNavigatorReviews',
  'navigator-offers': 'CoreIconIconsNavigatorOffers',
  'navigator-vacancies': 'CoreIconIconsNavigatorVacancies',
  'navigator-lectures': 'CoreIconIconsNavigatorLectures',
  'navigator-questions': 'CoreIconIconsNavigatorQuestions',
  'navigator-goods': 'CoreIconIconsNavigatorGoods',
  'navigator-more': 'CoreIconIconsNavigatorMore',
  box: 'CoreIconIconsBox',
  crown: 'CoreIconIconsCrown',
  'comment-user': 'CoreIconIconsCommentUser',
  star: 'CoreIconIconsStar',
  stats: 'CoreIconIconsStats',
  'shield-check': 'CoreIconIconsShieldCheck',
  'chart-histogram': 'CoreIconIconsChartHistogram',
  fox: 'CoreIconIconsFox',
  streams: 'CoreIconIconsStreams',
  transform: 'CoreIconIconsTransform',
  copy: 'CoreIconIconsCopy',
  'link-away': 'CoreIconIconsLinkAway',
  like: 'CoreIconIconsLike',
}

export default {
  name: 'CoreIcon',
  provide() {
    return {
      generateId: this.generateId,
    }
  },

  props: {
    /**
     * Название иконки
     */
    name: {
      type: String,
      default: 'search',
      validator: (value) => {
        return Object.keys(icons).includes(value)
      },
    },
  },

  computed: {
    currentIcon() {
      return this.getIcon(this.name)
    },
  },
  methods: {
    getIcon: (name) => icons[name] || 'CoreIconIconsSearch',
    generateId() {
      const chrs = 'abdehkmnpswxzABDEFGHKMNPQRSTWXZ'
      let str = ''
      for (let i = 0; i < 32; i++) {
        const pos = Math.floor(Math.random() * chrs.length)
        str += chrs.substring(pos, pos + 1)
      }
      return str
    },
  },
}
