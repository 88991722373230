
export default {
  name: 'MegasredaContentVideoGridCard',
  props: {
    post: {
      type: Object,
      default: null,
    },
    imgWidth: {
      type: Number,
      default: 295,
    },
  },
  data() {
    return {
      isSetShow: false,
    }
  },
  methods: {
    inView({ isInView }) {
      if (isInView && !this.isSetShow) {
        this.setShow()
      }
    },
    setShow() {
      this.$axios.$post('/activity/show/article', {
        username: this.post.author.username,
        articleId: this.post._id,
      })
      this.isSetShow = true
    },
  },
}
